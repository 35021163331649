// export const firebase = {
//     firebaseConfig: {
//         apiKey: "AIzaSyAvrrVr5SUbISFv5gbE9HKcx9pHo3Gu3Jc",
//         authDomain: "thairetrievesms.firebaseapp.com",
//         projectId: "thairetrievesms",
//         storageBucket: "thairetrievesms.appspot.com",
//         messagingSenderId: "191880840210",
//         appId: "1:191880840210:web:3b82d926c4efc3d6b944cb",
//         measurementId: "G-2SCHEG8G7Q"
      
//       }

   export const firebase = {
    firebaseConfig:{
      apiKey: "AIzaSyC_CphAvGOQZm7LNFZZ3iY13lDj3QjD8dY",
      authDomain: "thaiotpsms.firebaseapp.com",
      projectId: "thaiotpsms",
      storageBucket: "thaiotpsms.appspot.com",
      messagingSenderId: "143615946303",
      appId: "1:143615946303:web:cd8e295f9ba45789fd128a",
      measurementId: "G-Z5RLSKT36M"
    }
}